import React from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import Row from './Row';
import { panelTitle, h3 } from '../styles/typography';
import { media } from '../styles';
import { useAppear } from '../hooks/useAppear';

const StyledPush = styled(Row)`
    display: flex;

    ${media.mobile`
        flex-direction: column;
    `}
`;

const StyledLeft = styled.div`
    width: ${sizeAsBase(40)};
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)} ${props => props.theme.spacing.s80};
    box-shadow: 1px 0 0 0 ${props => props.theme.colors.border};

    ${media.large`
        width: ${sizeAsMax(40)};
        padding: 40px ${sizeAsMax(2)} 80px;
    `}

    ${media.mobile`
        padding-top: ${props => props.theme.spacing.s20};
        padding-bottom: ${props => props.theme.spacing.s20};
        width: 100%;
        box-shadow: none;
    `}
`;

const StyledRight = styled.div`
    width: ${sizeAsBase(22)};
    padding: ${props => props.theme.spacing.s140} ${sizeAsBase(2)};

    ${media.large`
        width: ${sizeAsMax(22)};
        padding: 140px ${sizeAsMax(2)};
    `}

    ${media.mobile`
        padding-top: 0;
        padding-bottom: ${props => props.theme.spacing.s80};
        width: 100%;
    `}
`;

const StyledTitle = styled.h2`
    ${panelTitle};
    margin-bottom: ${props => props.theme.spacing.s40};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s15};
    `}
`;

const StyledText = styled.div`
    ${h3};
    margin-bottom: ${props => props.theme.spacing.s30};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s20};
    `}
`;

const PushLargeImageLeft = ({ title, image, text, children }) => {
    const refAppear = useAppear();

    return (
        <StyledPush>
            <StyledLeft>
                <StyledTitle>{title}</StyledTitle>
                {image}
            </StyledLeft>
            <StyledRight ref={refAppear}>
                <StyledText dangerouslySetInnerHTML={{ __html: text }} />
                {children}
            </StyledRight>
        </StyledPush>
    );
};

export default PushLargeImageLeft;
