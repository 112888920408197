import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { h3 } from '../styles/typography';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { Play } from './Icons';
import GatsbyImage from 'gatsby-image';
import { withModal } from '../store/ModalProvider';
import ModalVideo from './ModalVideo';
import { buttonReset } from '../styles/others';
import { useAppear } from '../hooks/useAppear';

const StyledBox = styled.div`
    ${h3};
    width: ${sizeAsBase(28)};
    cursor: pointer;

    &.is-active,
    &.is-active + & {
        cursor: default;
    }

    & + & {
        margin-left: ${sizeAsBase(2)};
    }

    ${media.large`
        width: ${sizeAsMax(28)};

        & + & {
            margin-left: ${sizeAsMax(2)};
        }
    `}

    ${media.mobile`
        width: ${sizeAsBase(54)};

        & + & {
            margin-left: ${sizeAsBase(4)};
        }
    `}
`;

const StyledImage = styled(GatsbyImage)`
    position: relative;
    z-index: 2;
`;

const StyledText = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${props => props.theme.spacing.s20};
    transition: transform 1s ${props => props.theme.easings.easeOutExpo};
    transform: translate(0, -100%);

    h3 {
        ${h3};
        font-weight: normal;
        margin-left: ${props => props.theme.spacing.s10};
        transition: color 0.6s ${props => props.theme.easings.easeOutExpo};
    }

    span {
        margin-left: auto;
        transition: color 0.6s ${props => props.theme.easings.easeOutExpo};
    }

    .is-active &,
    .is-active + ${StyledBox} & {
        transform: translate(0, 0);
    }

    ${media.mobile`
        .is-active + ${StyledBox} & {
            transform: translate(0, -100%);
        }
    `}
`;

const StyledPlayLink = styled.button`
    ${buttonReset};

    &:hover ~ h3,
    &:hover ~ span {
        color: ${props => props.theme.colors.red};
    }

    ${Play} {
        vertical-align: top;
    }
`;

const PlayLink = withModal(({ modalKey, addModal, removeModal, hasModal, embed }) => {
    const handleClick = event => {
        event.stopPropagation();
        addModal(
            <ModalVideo key={modalKey} handleClose={() => removeModal(modalKey)}>
                <iframe src={embed} allowFullScreen />
            </ModalVideo>
        );
    };

    return (
        <StyledPlayLink onClick={handleClick}>
            <Play style={{ fontSize: 40 }} />
        </StyledPlayLink>
    );
});

const BoxVideo = ({ id, data, isActive, index, ...others }, ref) => {
    const minutes = Math.floor(data.duration / 60);
    const seconds = data.duration - minutes * 60;
    const refAppear = useAppear(0.2 * index);

    return (
        <StyledBox ref={ref} {...others} className={isActive && 'is-active'}>
            {data.cover && data.cover.local && data.cover.local.sharp && (
                <StyledImage fluid={data.cover.local.sharp.image} />
            )}
            <StyledText ref={refAppear}>
                <PlayLink modalKey={id} embed={data.embed} />
                <h3>{data.title}</h3>
                <span>
                    {minutes}:{seconds}
                </span>
            </StyledText>
        </StyledBox>
    );
};

export default forwardRef(BoxVideo);
