import React from 'react';
import styled from 'styled-components';
import { panelTitle } from '../styles/typography';
import Row, { rowShadowGreyCSS } from './Row';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { clearFix } from 'polished';
import BorderSeparator from './BorderSeparator';
import BoxVideo from './BoxVideo';
import LinkLarge from './LinkLarge';
import Carousel from './Carousel';

const StyledBlock = styled.div`
    ${clearFix()};
    position: relative;
    overflow: hidden;
    background: ${props => props.theme.colors.font};
    color: ${props => props.theme.colors.white};
`;

const StyledTitle = styled.h2`
    ${panelTitle};
`;

const StyledHeader = styled(Row)`
    ${rowShadowGreyCSS};
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};

    div {
        width: ${sizeAsBase(28)};
    }

    ${media.large`
        padding: ${props => props.theme.spacing.s40} ${sizeAsMax(2)};

        div {
            width: ${sizeAsMax(28)};
        }
    `}

    ${media.mobile`
        div {
            display: none;
        }
    `}
`;

const StyledRowGrey = styled(Row)`
    ${rowShadowGreyCSS};
    padding-left: ${sizeAsBase(2)};
    padding-bottom: ${props => props.theme.spacing.s80};
    overflow: initial;

    ${media.large`
        padding-left: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        padding-left: ${sizeAsBase(4)};
    `}
`;

// const StyledFooter = styled(Row)`
//     ${rowShadowGreyCSS};
//     padding: ${props => props.theme.spacing.s60} ${sizeAsBase(2)};
//     text-align: right;

//     ${media.large`
//         padding-left: ${sizeAsMax(2)};
//         padding-right: ${sizeAsMax(2)};
//     `}

//     ${media.mobile`
//         padding-top: ${props => props.theme.spacing.s40};
//         padding-bottom: ${props => props.theme.spacing.s40};
//     `}
// `;

const CarouselVideos = ({ title, text, videos }) => (
    <StyledBlock>
        <StyledHeader>
            <StyledTitle>{title}</StyledTitle>
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </StyledHeader>
        <StyledRowGrey>
            <Carousel>
                {videos.map(({ data, id }, index) => (
                    <BoxVideo key={id} data={data} id={id} index={index} />
                ))}
            </Carousel>
        </StyledRowGrey>
        <BorderSeparator color="grey" />
        {/* <StyledFooter>
            <LinkLarge>
                <span>—›</span> {textViewAll}
            </LinkLarge>
        </StyledFooter> */}
    </StyledBlock>
);

export default CarouselVideos;
