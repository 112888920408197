import React, { createRef, Children, Component, cloneElement } from 'react';
import { Swipeable } from 'react-swipeable';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    position: relative;
    transition: transform 0.9s ${props => props.theme.easings.easeOutQuint};
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 999em;
`;

class Carousel extends Component {
    constructor() {
        super(...arguments);

        this.handleClick = this.handleClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleSwipe = this.handleSwipe.bind(this);

        this.$wrapper = createRef(null);
        this.$children = Children.map(this.props.children, () => createRef(null));

        this.state = {
            index: 0,
            wrapperWidth: null
        };
    }

    componentDidMount() {
        window.addEventListener('load', this.handleResize);
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setWrapperWidth();
        this.goTo();
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleResize);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ wrapperWidth: null });
    }

    componentDidUpdate(prevProps, prevState) {
        const { index, wrapperWidth } = this.state;

        if (prevState.index !== index) {
            this.goTo();
        }

        if (prevState.wrapperWidth !== null && wrapperWidth === null) {
            this.setWrapperWidth();
            this.goTo();
        }
    }

    setWrapperWidth() {
        const $last = this.$children[this.$children.length - 1].current;

        this.setState({
            wrapperWidth: $last.offsetLeft + $last.offsetWidth + 1
        });
    }

    goTo() {
        const { index } = this.state;
        const { current } = this.$children[index];

        if (current) {
            this.setState({ translateLeft: -current.offsetLeft });
        }
    }

    handleClick(event) {
        const element = event.currentTarget;
        const index = Array.from(element.parentNode.children).indexOf(element);

        this.setState({ index });
    }

    handleSwipe({ dir }) {
        const { length } = this.$children;
        const { index } = this.state;

        if (dir === 'Left' && index + 1 < length) {
            this.setState({ index: index + 1 });
        }

        if (dir === 'Right' && index > 0) {
            this.setState({ index: index - 1 });
        }
    }

    render() {
        const { translateLeft, wrapperWidth, index } = this.state;

        const style = {
            transform: `translate(${translateLeft}px, 0)`,
            width: wrapperWidth
        };

        return (
            <Swipeable
                onSwipedLeft={this.handleSwipe}
                onSwipedRight={this.handleSwipe}
                trackMouse={true}
            >
                <div>
                    <StyledWrapper ref={this.$wrapper} style={style}>
                        {React.Children.map(this.props.children, (element, idx) =>
                            cloneElement(element, {
                                ref: this.$children[idx],
                                onClick: this.handleClick,
                                isActive: idx === index
                            })
                        )}
                    </StyledWrapper>
                </div>
            </Swipeable>
        );
    }
}

export default Carousel;
