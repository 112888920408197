import React from 'react';
import styled from 'styled-components';
import Row from './Row';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { panelTitle, h2 } from '../styles/typography';
import { media } from '../styles';
import { useAppear } from '../hooks/useAppear';

const StyledPush = styled(Row)`
    background: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)} ${props => props.theme.spacing.s110};

    ${media.mobile`
        padding-top: ${props => props.theme.spacing.s20};
        padding-bottom: ${props => props.theme.spacing.s80};
        overflow: initial;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            width: ${sizeAsBase(3)};
            background: ${props => props.theme.colors.blue};
            box-shadow: inset -1px 0 0 0 ${props => props.theme.colors.grey};
        }

        &:after {
            right: auto;
            left: 100%;
            box-shadow: inset 1px 0 0 0 ${props => props.theme.colors.grey};
        }
    `}
`;

const StyledTitle = styled.h2`
    ${panelTitle};
    margin-bottom: ${props => props.theme.spacing.s40};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s15};
    `}
`;

const StyledContent = styled.div`
    width: ${sizeAsBase(48)};
    margin: 0 auto;

    ${media.large`
        width: ${sizeAsMax(48)};
    `};

    ${media.mobile`
        width: 100%;
    `}
`;

const StyledText = styled.div`
    ${h2};
    margin-top: 30px;
    margin-bottom: 30px;
`;

const PushXLarge = ({ title, image, text, children }) => {
    const refAppear = useAppear();
    return (
        <StyledPush>
            <StyledTitle>{title}</StyledTitle>
            <StyledContent>
                {image}
                <div ref={refAppear}>
                    <StyledText dangerouslySetInnerHTML={{ __html: text }} />
                    {children}
                </div>
            </StyledContent>
        </StyledPush>
    );
};

export default PushXLarge;
