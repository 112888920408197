import React, { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import beziers from '../core/beziers';
import { useWindowSize } from '../hooks';
import { Close } from './Icons';
import { buttonReset } from '../styles/others';

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${props => props.theme.zIndex.modal};
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const PosedOverlay = posed.div({
    enter: {
        opacity: 1,
        transition: {
            ease: beziers.easeOutQuad,
            duration: 400
        }
    },
    exit: {
        opacity: 0,
        transition: {
            ease: beziers.easeOutQuad,
            duration: 300,
            delay: 200
        }
    }
});

const StyledOverlay = styled(PosedOverlay)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    z-index: 1;
    cursor: pointer;
`;

// Wrapper
const PosedWrapper = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        transition: {
            ease: beziers.easeOutExpo,
            duration: 800,
            delay: 350
        }
    },
    exit: {
        y: 50,
        opacity: 0,
        transition: {
            ease: beziers.easeOutQuad,
            duration: 300,
            delay: 100
        }
    }
});

const StyledModalWrapper = styled(PosedWrapper)`
    position: relative;
    width: 800px;
    height: 450px;
    background: ${props => props.theme.colors.black};
    z-index: 2;

    iframe {
        width: 100%;
        height: 100%;
    }
`;

const StyledClose = styled.button`
    ${buttonReset};
    position: absolute;
    bottom: 100%;
    right: 0;
    margin: 0 0 20px 0;
    color: ${props => props.theme.colors.white};

    ${Close} {
        font-size: 22px;
    }
`;

const ModalVideo = ({ handleClose, children }, ref) => {
    const { innerWidth, innerHeight } = useWindowSize();

    let width = (1160 / 1280) * innerWidth;
    let height = width / (16 / 9);

    if (height > (652 / 800) * innerHeight) {
        height = (652 / 800) * innerHeight;
        width = height * (16 / 9);
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.documentElement.classList.add('no-scroll');
        }

        return () => {
            if (typeof window !== 'undefined') {
                document.documentElement.classList.remove('no-scroll');
            }
        };
    }, []);

    return (
        <StyledModal ref={ref}>
            <StyledOverlay onClick={handleClose}>&nbsp;</StyledOverlay>
            <StyledModalWrapper style={{ width, height }}>
                <StyledClose onClick={handleClose} title="Fermer" ariaLabel="Fermer">
                    <Close />
                </StyledClose>
                {children}
            </StyledModalWrapper>
        </StyledModal>
    );
};

export default posed(forwardRef(ModalVideo))({});
