import React from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import RowTitled from './RowTitled';
import { h3 } from '../styles/typography';
import { media } from '../styles';
import Row from './Row';
import { useMedia } from '../hooks';
import { mqMobile } from '../hooks/useMedia';
import { useAppear } from '../hooks/useAppear';

const StyledContent = styled.div`
    display: flex;
    padding: 0 ${sizeAsBase(2)} ${props => props.theme.spacing.s100};

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
        padding-bottom: ${props => props.theme.spacing.s80};
    `}

    ${media.mobile`
        flex-direction: column;
    `}
`;

const StyledRight = styled.div`
    position: relative;
    margin-left: ${sizeAsBase(30)};
    width: ${sizeAsBase(23)};
    padding-top: ${props => props.theme.spacing.s20};
    z-index: 2;

    ${media.large`
        width: ${sizeAsMax(23)};
        margin-left: ${sizeAsMax(30)};
    `}

    ${media.mobile`
        margin-left: 0;
        padding-top: ${props => props.theme.spacing.s20};
        width: 100%;
    `}
`;

const StyledText = styled.div`
    ${h3};
    margin-bottom: ${props => props.theme.spacing.s30};
`;

const StyledRow = styled(RowTitled)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    & > h2 {
        position: relative;
        z-index: 2;
    }

    ${media.mobile`
        position: relative;
        height: auto;
    `}
`;

const StyledPush = styled(Row)`
    position: relative;
`;

const PushImageFullBg = ({ title, image, text, children }) => {
    const isMobile = useMedia(mqMobile, [true], false);
    const refAppear = useAppear();

    return (
        <StyledPush>
            {!isMobile && image}
            <StyledRow title={title}>
                {isMobile && image}
                <StyledContent>
                    <StyledRight ref={refAppear}>
                        <StyledText dangerouslySetInnerHTML={{ __html: text }} />
                        {children}
                    </StyledRight>
                </StyledContent>
            </StyledRow>
        </StyledPush>
    );
};

export default PushImageFullBg;
