import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import CoverImageText from '../components/CoverImageText';
import PushLargeImageLeft from '../components/PushLargeImageLeft';
import GatsbyImage from 'gatsby-image';
import PushXLarge from '../components/PushXLarge';
import LinkButton, { LinkButtonPlain } from '../components/LinkButton';
import PushSquare, { StyledPushSquare } from '../components/PushSquare';
import PushDouble from '../components/PushDouble';
import PushImageFullBg from '../components/PushImageFullBg';
import CarouselVideos from '../components/CarouselVideos';
import BorderSeparator from '../components/BorderSeparator';
import NewsListExcerpt from '../components/NewsListExcerpt';
import { sizeAsBase } from '../styles/utils';
import { media } from '../styles';
import LinkAnchor from '../components/LinkAnchor';
import { withModal } from '../store/ModalProvider';
import SideModal from '../components/SideModal';
import GridPeople from '../components/GridPeople';

const StyledPage = styled.div`
    ${media.mobile`
        ${StyledPushSquare} + ${StyledPushSquare} {
            position: relative;
            padding-top: ${props => props.theme.spacing.s40};

            &:before {
                content: '';
                position: absolute;
                top: 0;
                height: 1px;
                right: ${sizeAsBase(-3)};
                left: ${sizeAsBase(-3)};
                background: ${props => props.theme.colors.border};
            }
        }
    `}
`;

const StyledImageLargeMobile = styled(GatsbyImage)`
    ${media.mobile`
        margin-left: ${sizeAsBase(-2)};
        margin-right: ${sizeAsBase(-2)};
    `}
`;

const Home = ({ data, pageContext, addModal, hasModal, removeModal }) => {
    const {
        seo_title,
        seo_description,
        cover_text,
        cover_text_button,
        cover_images,
        team_title,
        team_text,
        team_link,
        team_text_button,
        team_image,
        rythmology_title,
        rythmology_text,
        rythmology_link,
        rythmology_text_button,
        rythmology_image,
        treatments_title,
        treatments_tests_title,
        treatments_tests_text,
        treatments_tests_image,
        treatments_pathologies_title,
        treatments_pathologies_text,
        treatments_pathologies_image,
        medicalcare_title,
        medicalcare_text,
        medicalcare_text_button,
        medicalcare_link,
        medias_title,
        medias_text,
        news_title,
        news_text_button,
        news_link
    } = data.page.data;

    const videos = data.videos
        ? data.videos.edges.filter(({ node }) => node !== null).map(({ node }) => node)
        : [];

    const posts = data.posts
        ? data.posts.edges.filter(({ node }) => node !== null).map(({ node }) => node)
        : [];

    const filteredDoctors = data.doctors.edges
        .filter(({ node }) => node !== null)
        .map(({ node }) => node);

    const handleModalPoseComplete = () => {
        if (hasModal(cover_text_button) !== true) {
            document.documentElement.classList.remove('no-scroll');
        }
    };

    const handleCloseModal = () => {
        removeModal(cover_text_button);
    };

    const handleOpenModal = event => {
        event.preventDefault();
        const modal = (
            <SideModal
                key={cover_text_button}
                handleClose={handleCloseModal}
                onPoseComplete={handleModalPoseComplete}
                title={cover_text_button}
            >
                <GridPeople people={filteredDoctors} />
            </SideModal>
        );

        addModal(modal);
    };

    return (
        <StyledPage>
            <Helmet>
                <title>{seo_title}</title>
                {seo_description && <meta name="description" content={seo_description} />}
            </Helmet>
            <CoverImageText text={cover_text.html} images={cover_images}>
                {team_link && (
                    <LinkButton onClick={handleOpenModal}>{cover_text_button}</LinkButton>
                )}
            </CoverImageText>
            <BorderSeparator />
            <PushLargeImageLeft
                title={team_title}
                text={team_text.html}
                image={<StyledImageLargeMobile fluid={team_image.local.sharp.image} />}
            >
                {team_link && (
                    <LinkAnchor
                        to={team_link.url}
                        anchor="work-team"
                        component={<LinkButtonPlain />}
                    >
                        {team_text_button}
                    </LinkAnchor>
                )}
            </PushLargeImageLeft>
            <BorderSeparator />
            <PushXLarge
                title={rythmology_title}
                text={rythmology_text.html}
                image={<StyledImageLargeMobile fluid={rythmology_image.local.sharp.image} />}
            >
                {rythmology_link && (
                    <LinkButtonPlain to={rythmology_link.url}>
                        {rythmology_text_button}
                    </LinkButtonPlain>
                )}
            </PushXLarge>
            <BorderSeparator />
            {rythmology_link && (
                <PushDouble title={treatments_title}>
                    <PushSquare
                        to={rythmology_link.url}
                        title={treatments_tests_title}
                        text={treatments_tests_text.html}
                        anchor="diagnostics"
                        image={<GatsbyImage fluid={treatments_tests_image.local.sharp.image} />}
                    />
                    <PushSquare
                        to={rythmology_link.url}
                        title={treatments_pathologies_title}
                        text={treatments_pathologies_text.html}
                        anchor="pathologies"
                        index={1}
                        image={
                            <GatsbyImage fluid={treatments_pathologies_image.local.sharp.image} />
                        }
                    />
                </PushDouble>
            )}
            <BorderSeparator />
            <PushImageFullBg
                title={medicalcare_title}
                text={medicalcare_text.html}
                image={<GatsbyImage fluid={data.illu.sharp.image} />}
            >
                {medicalcare_link && (
                    <LinkButtonPlain to={medicalcare_link.url}>
                        {medicalcare_text_button}
                    </LinkButtonPlain>
                )}
            </PushImageFullBg>
            <BorderSeparator />
            {videos.length > 0 && (
                <CarouselVideos videos={videos} title={medias_title} text={medias_text.html} />
            )}
            {posts.length > 0 && (
                <NewsListExcerpt
                    link={news_link}
                    posts={posts}
                    title={news_title}
                    textViewAll={news_text_button}
                    lang={pageContext.lang}
                />
            )}
        </StyledPage>
    );
};

export default withModal(Home);

export const pageQuery = graphql`
    query HomeQuery($lang: String!) {
        illu: file(relativePath: { eq: "prise-en-charge.jpg" }) {
            ...sharp1550x775
        }
        page: prismicPageHome(lang: { eq: $lang }) {
            uid
            data {
                seo_title
                seo_description
                title {
                    text
                }
                cover_text {
                    html
                }
                cover_images {
                    image {
                        local: localFile {
                            ...CoverImage
                        }
                    }
                }
                cover_text_button
                team_title
                team_text {
                    html
                }
                team_image {
                    local: localFile {
                        ...fixed900x598
                    }
                }
                team_link {
                    url
                }
                team_text_button
                rythmology_title
                rythmology_text {
                    html
                }
                rythmology_link {
                    url
                }
                rythmology_text_button
                rythmology_image {
                    local: localFile {
                        ...sharp1200x800
                    }
                }
                treatments_title
                treatments_tests_title
                treatments_tests_text {
                    html
                }
                treatments_tests_image {
                    local: localFile {
                        ...sharp675x645
                    }
                }
                treatments_pathologies_title
                treatments_pathologies_text {
                    html
                }
                treatments_pathologies_image {
                    local: localFile {
                        ...sharp675x645
                    }
                }
                medicalcare_title
                medicalcare_link {
                    url
                }
                medicalcare_text {
                    html
                }
                medicalcare_text_button
                medias_title
                medias_text {
                    html
                }
                news_title
                news_text_button
                news_link {
                    url
                }
            }
        }
        videos: allPrismicVideo(filter: { lang: { eq: $lang } }) {
            edges {
                node {
                    id: prismicId
                    uid
                    data {
                        title
                        embed
                        duration
                        cover {
                            local: localFile {
                                ...sharp700x394
                            }
                        }
                    }
                }
            }
        }
        posts: allPrismicNewsPost(
            filter: { lang: { eq: $lang } }
            sort: { fields: [first_publication_date], order: DESC }
            limit: 3
        ) {
            edges {
                node {
                    id: prismicId
                    date: first_publication_date(formatString: "MMM YYYY", locale: $lang)
                    data {
                        title {
                            text
                        }
                        excerpt {
                            html
                        }
                    }
                }
            }
        }
        doctors: allPrismicProfile(
            filter: { lang: { eq: $lang }, data: { type: { eq: "doctor" } } }
            sort: { order: ASC, fields: data___family_name }
        ) {
            edges {
                node {
                    uid
                    data {
                        title {
                            text
                        }
                        type
                        image {
                            local: localFile {
                                ...sharp450x675
                            }
                        }
                        external_button_url {
                            target
                            url
                        }
                    }
                }
            }
        }
    }
`;
