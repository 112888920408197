import React from 'react';
import styled from 'styled-components';
import { h3 } from '../styles/typography';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import LinkAnchor from './LinkAnchor';
import { linkReset } from '../styles/others';
import { useAppear } from '../hooks/useAppear';
import { transitionLinkColor } from './LinkHover';

export const StyledPushSquare = styled(LinkAnchor)`
    ${linkReset};
    display: flex;
    flex-direction: column;
    padding: 0 ${sizeAsBase(2)} ${props => props.theme.spacing.s40};

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 2s ${props => props.theme.easings.easeOutExpo};
    }

    &:hover {
        picture {
            transform: scale(1.1);
        }
    }
`;

const StyledTitle = styled.h3`
    ${h3};
    ${transitionLinkColor};
    margin-top: ${props => props.theme.spacing.s40};
    margin-bottom: ${props => props.theme.spacing.s10};

    a:hover & {
        color: ${props => props.theme.colors.red};
    }

    ${media.mobile`
        margin-top: ${props => props.theme.spacing.s20};
    `}
`;

const PushSquare = ({ title, image, text, index, ...others }) => {
    const refAppear = useAppear(0.2 * index);
    return (
        <StyledPushSquare {...others}>
            {image}
            <div ref={refAppear}>
                <StyledTitle>{title}</StyledTitle>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </StyledPushSquare>
    );
};

PushSquare.defaultProps = {
    index: 0,
    anchor: false
};

export default PushSquare;
