import React from 'react';
import styled from 'styled-components';
import { useMedia } from '../hooks';
import { mqMobile } from '../hooks/useMedia';
import { useAppear } from '../hooks/useAppear';
import Carousel from './Carousel';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import GatsbyImage from 'gatsby-image';
import ConditionalWrap from './ConditionalWrap';
import Link from './Link';
import LinkHover from './LinkHover';
import { LinkButtonPlain } from './LinkButton';

const GridPeople = ({ people, onClickItem, carouselOnMobile }) => {
    const isMobile = useMedia(mqMobile, [true], false);

    const content = people.map(({ uid, data }) => {
        const refAppear = useAppear();

        const url =
            data.external_button_url && data.external_button_url.url
                ? data.external_button_url.url
                : null;

        const target =
            data.external_button_url && data.external_button_url.target
                ? data.external_button_url.target
                : null;

        const isLink = url !== null || onClickItem instanceof Function;

        return (
            <StyledPeople key={uid}>
                <ConditionalWrap
                    condition={isLink}
                    wrap={children => (
                        <Link to={url} target={target} onClick={event => onClickItem(data, event)}>
                            {children}
                        </Link>
                    )}
                >
                    <StyledPeopleImage fluid={data.image.local.sharp.image} />
                </ConditionalWrap>
                <StyledPeopleContent>
                    <ConditionalWrap
                        condition={isLink}
                        wrap={children => (
                            <LinkHover
                                to={url}
                                target={target}
                                onClick={event => onClickItem(data, event)}
                            >
                                {children}
                            </LinkHover>
                        )}
                    >
                        <h3>{data.title.text}</h3>
                    </ConditionalWrap>
                    <div ref={refAppear}>
                        {data.specialties && data.specialties.html && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.specialties.html
                                }}
                            />
                        )}
                        {data.external_button_text && data.external_button_url.url && (
                            <LinkButtonPlain
                                to={data.external_button_url.url}
                                target={data.external_button_url.target}
                            >
                                {data.external_button_text}
                            </LinkButtonPlain>
                        )}
                    </div>
                </StyledPeopleContent>
            </StyledPeople>
        );
    });

    return (
        <Module>
            <ConditionalWrap
                condition={isMobile && carouselOnMobile}
                wrap={children => <Carousel>{children}</Carousel>}
            >
                {content}
            </ConditionalWrap>
        </Module>
    );
};

GridPeople.defaultProps = {
    carouselOnMobile: false,
    people: [],
    onClickItem: false
};

export default GridPeople;

const Module = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${sizeAsBase(2)};
    grid-row-gap: ${props => props.theme.spacing.s60};
    margin-left: ${sizeAsBase(2)};
    margin-right: ${sizeAsBase(2)};
    padding: ${props => props.theme.spacing.s80} 0;

    ${media.large`
        grid-column-gap: ${sizeAsMax(2)};
        margin-left: ${sizeAsMax(2)};
        margin-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        grid-template-columns: 1fr;
    `}
`;

const StyledPeopleContent = styled.div`
    h3 {
        font-weight: bold;
        margin-bottom: ${props => props.theme.spacing.s20};
    }

    div {
        font-size: ${props => props.theme.fontSize.xsmall};
        line-height: ${props => props.theme.lineHeight.xsmall};
    }

    a {
        margin-top: ${props => props.theme.spacing.s20};
    }

    li:before {
        content: '- ';
    }
`;

const StyledPeople = styled.div`
    a {
        cursor: pointer;
    }

    ${media.mobile`
        width: ${sizeAsBase(58)};

        ${StyledPeopleContent} {
            opacity: ${props => (props.isActive ? 1 : 0)};
        }

        & + & {
            margin-left: ${sizeAsBase(2)};
        }
    `}
`;

const StyledPeopleImage = styled(GatsbyImage)`
    margin-bottom: ${props => props.theme.spacing.s20};
`;
