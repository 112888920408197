import React from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { h3, xsmall } from '../styles/typography';
import { useAppear } from '../hooks/useAppear';

const StyledNewsItem = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};
    width: 100%;

    & + & {
        box-shadow: inset 0 1px 0 0 ${props => props.theme.colors.grey};
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        display: block;
        background:${props => props.theme.colors.white};
        padding-left: ${sizeAsBase(6)};
        padding-right: ${sizeAsBase(6)};
    `}
`;

const StyledLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    width: ${sizeAsBase(18)};

    ${media.large`
        width: ${sizeAsMax(18)};
    `}

    ${media.mobile`
        width: auto;
        margin-bottom: ${props => props.theme.spacing.s40};
    `}
`;

const StyledRight = styled.div`
    width: ${sizeAsBase(16)};

    ${media.large`
        width: ${sizeAsMax(16)};
    `}

    ${media.mobile`
        width: auto;
    `}
`;

const StyledTitle = styled.h4`
    ${h3}
    margin-bottom: ${props => props.theme.spacing.s30};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s10};
    `}
`;

const StyledDate = styled.span`
    ${xsmall}
    color: ${props => props.theme.colors.red};
`;

const NewsItemExcerpt = ({ data, date }) => {
    const refAppearLeft = useAppear();
    const refAppearRight = useAppear(0.2);

    return (
        <StyledNewsItem>
            <StyledLeft ref={refAppearLeft}>
                <StyledTitle>{data.title.text}</StyledTitle>
                <StyledDate>{date}</StyledDate>
            </StyledLeft>
            <StyledRight
                ref={refAppearRight}
                dangerouslySetInnerHTML={{ __html: data.excerpt.html }}
            />
        </StyledNewsItem>
    );
};

export default NewsItemExcerpt;
