import React from 'react';
import styled from 'styled-components';
import { panelTitle } from '../styles/typography';
import Row, { rowShadowGreyCSS } from './Row';
import BorderSeparator from './BorderSeparator';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import NewsItemExcerpt from './NewsItemExcerpt';
import LinkLarge from './LinkLarge';

const StyledBlock = styled.div`
    ${media.mobile`
        background: ${props => props.theme.colors.red};
    `}
`;

const StyledRow = styled(Row)`
    ${rowShadowGreyCSS};
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)} 0;
    padding-right: 0;

    ${media.large`
        padding-left: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        padding-left: 0;
        padding-top: ${props => props.theme.spacing.s20};
    `}
`;

const StyledTitle = styled.h2`
    ${panelTitle};
    color: ${props => props.theme.colors.white};
    position: relative;
    margin-bottom: ${props => props.theme.spacing.s40};

    ${media.mobile`
        padding-left: ${sizeAsBase(2)};
        padding-right: ${sizeAsBase(2)};
        margin-bottom: ${props => props.theme.spacing.s15};
    `}
`;

const StyledPane = styled.div`
    width: ${sizeAsBase(22)};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: ${props => props.theme.colors.red};

    ${media.large`
        width: ${sizeAsMax(22)};
    `}

    ${media.mobile`
        display: none;
    `}
`;

const StyledContent = styled.div`
    position: relative;
    margin-left: ${sizeAsBase(20)};

    ${media.large`
        margin-left: ${sizeAsMax(20)};
    `}

    ${media.mobile`
        margin-left: 0;
    `}
`;

const StyledBorderSeparator = styled(BorderSeparator)`
    width: auto;
    margin-left: ${sizeAsBase(-5)};
    margin-right: ${sizeAsBase(-3)};

    ${media.large`
        margin-left: ${sizeAsMax(-5)};
        margin-right: ${sizeAsMax(-3)};
    `};
`;

const StyledFooter = styled.footer`
    position: relative;
    text-align: right;
    padding: ${props => props.theme.spacing.s60} ${sizeAsBase(2)};

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        background: ${props => props.theme.colors.white};
    `}
`;

const NewsListExcerpt = ({ posts, title, textViewAll, link }) => {
    return (
        <StyledBlock>
            <StyledRow>
                <StyledPane />
                <StyledTitle>{title}</StyledTitle>
                <StyledBorderSeparator color="grey" />
                <StyledContent>
                    {posts.map(({ id, ...others }) => (
                        <NewsItemExcerpt key={id} {...others} />
                    ))}
                </StyledContent>
                <StyledBorderSeparator color="grey" />
                <StyledFooter>
                    <LinkLarge to={link.url}>
                        <span>—›</span> {textViewAll}
                    </LinkLarge>
                </StyledFooter>
            </StyledRow>
        </StyledBlock>
    );
};

export default NewsListExcerpt;
