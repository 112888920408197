import React from 'react';
import styled from 'styled-components';
import RowTitled from './RowTitled';
import { media } from '../styles';

const StyledPush = styled(RowTitled)`
    &:after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background: ${props => props.theme.colors.border};
    }

    ${media.mobile`
        &:after {
            display: none;
        }
    `}
`;

const StyledContent = styled.div`
    display: flex;

    & > * {
        width: 50%;
    }

    ${media.mobile`
        flex-direction: column;

        & > * {
            width: 100%;
        }
    `}
`;

const PushDouble = ({ title, children }) => {
    return (
        <StyledPush title={title}>
            <StyledContent>{children}</StyledContent>
        </StyledPush>
    );
};

export default PushDouble;
